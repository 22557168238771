import dayjs from 'dayjs';

import type {Prisma} from '@rockawayxlabs/observatory-database';
import {type FeatureFlags, containsFeatureFlag, parseFeatureFlags} from '@rockawayxlabs/observatory-utils';

export interface URLParamsOptions<OrderBy> {
  defaultSort: OrderBy;
  defaultOrder: Prisma.SortOrder;
  defaultPagination: Pick<Pagination, 'skip' | 'take'>;
}

const getDefaultOptions = <OrderBy>(): URLParamsOptions<OrderBy> => ({
  defaultSort: '' as OrderBy,
  defaultOrder: 'desc',
  defaultPagination: {
    skip: 0,
    take: 100,
  },
});

export class URLParams<OrderBy extends string> {
  private url: URL;
  private options: URLParamsOptions<OrderBy>;

  constructor(url: string, options?: Partial<URLParamsOptions<OrderBy>>) {
    this.url = new URL(url);
    this.options = {
      ...getDefaultOptions<OrderBy>(),
      ...options,
    };
  }

  public static fromSearch<OrderBy extends string>(search: string, options?: Partial<URLParamsOptions<OrderBy>>) {
    const searchWithPrefix = search.startsWith('?') ? search : `?${search}`;
    return new URLParams(`https://observatory.zone${searchWithPrefix}`, options);
  }

  public getFeatureFlags(): FeatureFlags {
    const flags = this.getAllStrings<string>('feature', []).flatMap(flag => parseFeatureFlags(flag));

    return {
      nodeClaim: containsFeatureFlag(flags, 'nodeClaim'),
      validatorOutages: containsFeatureFlag(flags, 'validatorOutages'),
      validatorScoreHistory: containsFeatureFlag(flags, 'validatorScoreHistory'),
    };
  }

  public getSort(): OrderBy {
    const sort = this.url.searchParams.get('sort') as OrderBy | null;
    return sort ?? this.options.defaultSort;
  }

  public getOrder(): Prisma.SortOrder {
    const order = this.url.searchParams.get('order');
    return URLParams.isSortOrder(order) ? order : this.options.defaultOrder;
  }

  public getPagination() {
    const {skip: defaultSkip, take: defaultTake} = this.options.defaultPagination;
    const skip = this.getInteger('skip', defaultSkip);
    const take = this.getInteger('take', defaultTake);
    return {
      skip: skip % take === 0 ? skip : 0,
      take,
    };
  }

  public getDate(key: string, fallback: Date): Date;
  public getDate(key: string, fallback?: Date): Date | undefined;
  public getDate(key: string, fallback?: Date) {
    const dateParam = this.getString(key);
    if (typeof dateParam !== 'string') {
      return fallback;
    }

    const date = dayjs(dateParam);
    return date.isValid() ? date.toDate() : fallback;
  }

  public getInteger(key: string, fallback: number): number;
  public getInteger(key: string, fallback?: number): number | undefined;
  public getInteger(key: string, fallback?: number) {
    const value = this.url.searchParams.get(key);
    if (typeof value !== 'string' || !Number.isInteger(+value) || +value < 0) {
      return fallback;
    }
    return +value;
  }

  public getFloat(key: string, fallback: number): number;
  public getFloat(key: string, fallback?: number): number | undefined;
  public getFloat(key: string, fallback?: number) {
    const value = this.url.searchParams.get(key);
    if (typeof value !== 'string' || Number.isNaN(+value) || +value < 0) {
      return fallback;
    }
    return +value;
  }

  public getBoolean(key: string, fallback = false) {
    const value = this.getString(key);
    return ['1', 'true', 'yes'].includes(value ?? '') ? true : fallback;
  }

  public getString(key: string, fallback: string): string;
  public getString(key: string, fallback?: string): string | undefined;
  public getString(key: string, fallback?: string) {
    return this.url.searchParams.get(key) ?? fallback;
  }

  public getAllStrings<T extends string>(key: string, fallback: T[]): T[];
  public getAllStrings<T extends string>(key: string, fallback?: T[]): T[] | undefined;
  public getAllStrings<T extends string>(key: string, fallback?: T[]) {
    const params = this.url.searchParams.getAll(key);
    return params.length > 0 ? params : fallback;
  }

  public getDuration(key: string, fallback: string, allowedDurations?: string[]) {
    const durationString = this.getString(key, fallback);
    let duration = dayjs.duration(durationString);

    if (duration.toISOString() !== durationString || (allowedDurations && !allowedDurations.includes(durationString))) {
      return fallback;
    }

    return duration.toISOString();
  }

  public getSyncDate() {
    return this.getDate('sync-date');
  }

  static isSortOrder(order: unknown): order is Prisma.SortOrder {
    const validOrders: Prisma.SortOrder[] = ['asc', 'desc'];
    return typeof order === 'string' && validOrders.includes(order as any);
  }
}
